import React from 'react';
import { useTranslation } from 'react-i18next';
import { VictoryPie } from 'victory-pie';

const myData = [
  { x: 'Javascript', y: 1000 },
  { x: 'CSS', y: 200 },
  { x: 'html', y: 200 },
];

const ChartReact = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ height: 320 }}>
        <div className="textChart mt-4">
        {t('front_end')}
        </div>
        <VictoryPie
          data={myData}
          colorScale={['DodgerBlue', 'Gold', 'Coral']}
          radius={130}
        />
      </div>
    </>
  );
};
export default ChartReact;
