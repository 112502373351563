import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import Container from 'react-bootstrap/Container';
import Footer from './components/Footer';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/ContactUs';
import NavbarHeader from './components/NavbarHeader';
import ProjectScreen from './screens/ProjectScreen';

function App() {
  
  return (
    <BrowserRouter>
      <div className=" d-flex flex-column site-container">
        <NavbarHeader />
        <main>
          <Container fluid="true">
            <Routes>
              <Route
                exact
                path="/product/:id"
                element={<ProductScreen />}
              ></Route>
              <Route exact path="/projects" element={<ProjectScreen />}></Route>
              <Route exact path="/aboutus" element={<AboutUs />}></Route>
              <Route exact path="/contactus" element={<ContactUs />}></Route>
              <Route path="/" element={<HomeScreen />} exact></Route>
            </Routes>
          </Container>
          {/* <Routes>
            <Route path="/" element={<HomeScreen />} exact></Route>
          </Routes> */}
        </main>
        <footer>
          <Footer />
        </footer>
        {/* <footer>
          <div>
            
            <div>All right reserved</div>{' '}
          </div>
        </footer> */}
      </div>
    </BrowserRouter>
  );
}
export default App;
