import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import SingleCard from '../components/SngleCard';
import TextAnimation from '../components/TextAnimation';
import { initialProjects } from '../initialProjects';

export default function ProjectScreen() {
  const { t } = useTranslation();
  const [mydata, setdata] = useState(initialProjects);
  const [loading, setLoading] = useState(false);

  const bannerImage = '../images/projects.jpg';

  return (
    <>
      <div className="imgHeaderService ">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>
        <div className="content">
          <h6>
            <TextAnimation />
          </h6>
          <strong>{t('project_page')}</strong>
        </div>
      </div>
      <div className="container">
        <div className="my-3">
          <Row>
            <h1 className="h1-title">{t('project_page')}</h1>
          </Row>
          <Row className="flex flex-wrap justify-content-center bg-color-gray">
            {loading ? (
              <div>{/* <img src={spinner} alt="" /> */}</div>
            ) : (
              mydata.oldProjects.map((project) => {
                return <SingleCard data={project} key={project.projectName} />;
              })
            )}
          </Row>
        </div>
      </div>
    </>
  );
}
