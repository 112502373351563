const data = {
  products: [
    {
      _id: '1',
      name: 'web_development',
      image: '/images/web.jpg',
      imageBranchf: ['Business Website','Personal Website','Ecommerce Store'],
      
      description:
        'windows_statement',
    },
    {
      _id: '2',
      name: 'window_app',
      image: '/images/windows.jpg',
      description:
        'windows_statement',
    },
    {
      _id: '3',
      name: 'web_host',
      image: '/images/host.jpg',
      description:
        'windows_statement',
    },
    {
      _id: '4',
      name: 'cosult',
      image: '/images/consul.jpg',
      description:
        'windows_statement',
    },
  ],
};
export default data;
