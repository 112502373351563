import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import data from '../data';
import Product from '../components/Product';

export default function Work() {
  const { t } = useTranslation();
  return (
    <div className="container  shipping-info ">
      <Row>
        <h3 className="h1-title">{t('our_service')}</h3>

        <p>{t('our_quot')}</p>
      </Row>
      <Row>
        {data.products.map((product) => (
          <Col key={product._id} sm={6} md={4} lg={3} className="mb-3 reveal">
            <Product product={product}></Product>
          </Col>
        ))}
      </Row>
    </div>
  );
}
