import React from 'react';
import { useTranslation } from 'react-i18next';
import TextAnimation from './TextAnimation';
// import headerImg from '../images/bannermain.png';

export default function Header() {
  const { t } = useTranslation();
  const headerImg = '../images/bannermain.png';
  return (
    <div>
      <div className="imgHeader d-flex justify-content-center align-items-center h-100">
        <img
          className="d-flex w-100 bg-image"
          src={headerImg}
          alt={headerImg}
        ></img>

        <div>
          <div className="content">
            <h6>
              <TextAnimation />
            </h6>
            <p>{t('main_title')}</p>
          </div>
        </div>
      </div>
      </div>
     
    
  );
}
