import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextAnimation from '../components/TextAnimation';
import Mailer from '../components/Mailer';


export default function ContactUs() {
  const { t } = useTranslation();
  const bannerImage = '../images/contact.jpg';
  const bannerMore = '../images/more.jpg';

  return (
    <>
      <div className="imgHeaderService d-flex justify-content-center align-items-center h-100">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>

        <div>
          <div className="content">
            <h6>
              <TextAnimation />
            </h6>
            <strong>{t('about_Us')}</strong>
          </div>
        </div>
      </div>

      <div className="container d-flex my-4">
        <div>
          <Row>
            <h4>
              <strong>{t('Contact_us')}</strong>
            </h4>
          </Row>
          <Row>
            <Col sm={6}>
              <Mailer></Mailer>
            </Col>
            <Col
              sm={6}
              className="d-flex justify-content-center align-center align-items-center agency "
            >
              <h2 className="text-color1">{t('contact_statement')}</h2>
            </Col>

            <div className="image-group"></div>
          </Row>
        </div>
      </div>
      <div className="bgcontact-image"></div>
      <div className="bgcontact-image d-flex">
        <div className="transparent w-100">
          <div className="container">
            <div className="row">
              <div className="col-sm-5 col-md-3">
                <img className="img-contact w-100" src={bannerMore} alt={bannerMore}></img>
              </div>
              <div className="col-sm-5 col-md-9 reveal">
                <h4>
                  <strong> {t('section_contact')}</strong>
                </h4>
                <ul className="text-justify">
                  <li> {t('job_one')}</li>
                  <li>{t('job_two')}</li>
                  <li>{t('job_three')}</li>
                  <li>{t('job_four')}</li>
                  <li>{t('job_six')}</li>
                  <li>{t('job_five')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
