import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
// import Button from "react-bootstrap/Button";

export default function Category(props) {
  const { t } = useTranslation();
  const { category } = props;
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Card className="card-2 exampleOurWrk reveal">
      <img
        className="medium workImage"
        src={category.project_img}
        alt={category.projectName}
      />

      <Card.Body className="contentOurWork">
        {/* <Link to="/projects" className="item">
          <i className="fa fa-expand sizeOfIt"></i>
        </Link> */}
        <Card.Text>{category.projectName}</Card.Text>
        {/* <Link  className="text-link" to="/projects" onClick={scrollToTop}>{t('go_project')}</Link> */}
      </Card.Body>
    </Card>
  );
}
