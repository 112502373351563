import React from 'react';
import { useTranslation } from 'react-i18next';
import { VictoryPie } from 'victory-pie';

const myData = [
  { x: 'Node.js/Express-server', y: 300 },
  { x: 'Redux', y: 100 },
  { x: 'MangoDB', y: 200 },
];

const ChartBackend = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ height: 320 }}>
        <div className="textChart mt-4">{t('back_end')}</div>

        <VictoryPie
          data={myData}
          colorScale={['DodgerBlue', 'Gold', 'Coral']}
          radius={130}
        />
      </div>
    </>
  );
};
export default ChartBackend;
